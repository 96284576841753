import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPages } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import Badge from '../../components/bootstrap/Badge';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import Alert, { AlertHeading, AlertLink } from '../../components/bootstrap/Alert';
// import AuthContext from '../../contexts/authContext';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../components/bootstrap/OffCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { signOut } from '../../api/account';
import User6 from '../../assets/img/user6.png';
import * as API from '../../api/ramateVerify';
import useSWR from 'swr';
import { CrmLoadAnnouncementListPublicResponse } from '../../swagger/backend';
import { ANNOUNCEMENTS_TYPE } from '../../common/status/status';
import moment from 'moment';

const User = () => {
	// const { userData, setUser } = useContext(AuthContext);
	const userData = useSelector((state: RootState) => state.user);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [notificationModal, setNotificationModal] = useState<any>(null);
	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const [announcementCount, setAnnouncementCount] = useState<number>(0);

	const { t } = useTranslation(['translation', 'menu']);

	const { data: announcements = { announcementList: [] }, mutate: revalidate } = useSWR<CrmLoadAnnouncementListPublicResponse>(`/announcement/public`, () =>
		API.loadAnnouncementPublic(2),
	);

	function handleClickSetting() {
		if (userData.firmInfo.firmId) {
			navigate(
				`/settings`,
				// @ts-ignore
				handleItem(),
			);
		} else {
			navigate(
				`/admin-settings`,
				// @ts-ignore
				handleItem(),
			);
		}
	}

	useEffect(() => {
		if (announcements.announcementList) {
			const total = announcements.announcementList.reduce((state, current) => {
				if (moment(current.expireDate) > moment()) {
					return (state += 1);
				}
				return state;
			}, 0);
			setAnnouncementCount(total);
		}
	}, [announcements.announcementList]);

	return (
		<>
			<div className={classNames('user', { open: collapseStatus })} role='presentation' onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img srcSet={userData?.firmInfo?.imgUrl} src={userData?.firmInfo?.imgUrl || User6} alt='Avatar' width={128} height={128} />
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{/* {`${userData?.firmInfo?.name} ${userData?.userInfo?.userName}`} */}
						{userData?.firmInfo?.name || '根垣科技'}
						{/* <br />
						{userData?.userInfo?.userName} */}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>{userData?.userInfo?.userName}</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button icon='AccountBox' onClick={() => navigate(`../${demoPages.appointment.subMenu.employeeID.path}/${userData?.userInfo?.userId}`)}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button icon={darkModeStatus ? 'DarkMode' : 'LightMode'} onClick={() => setDarkModeStatus(!darkModeStatus)} aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../${demoPages.appointment.subMenu.employeeID.path}/${userData?.userInfo?.userId}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Profile')}</span>
								</span>
							</span>
						</div> */}
						<div role='presentation' className='navigation-item cursor-pointer' onClick={handleClickSetting}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Settings' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Settings')}</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon={darkModeStatus ? 'DarkMode' : 'LightMode'} color={darkModeStatus ? 'info' : 'warning'} className='navigation-icon' />
									<span className='navigation-text'>{darkModeStatus ? t('menu:DarkMode') : t('menu:LightMode')}</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setNotificationModal(true);
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon={'Notifications'} className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:ServiceNotification')}
										{collapseStatus && announcementCount > 0 && (
											<Badge isLight={darkModeStatus} color={'danger'} className='m-2'>
												<span>{announcementCount}</span>
											</Badge>
										)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								// if (setUser) {
								// 	setUser('');
								// }
								// navigate(`../${demoPages.login.path}`);
								signOut({
									firmId: JSON.parse(localStorage.getItem('ramate_crm_auth') || '')?.firmId,
									userId: JSON.parse(localStorage.getItem('ramate_crm_auth') || '')?.userId,
									deviceId: JSON.parse(localStorage.getItem('ramate_crm_auth') || '')?.deviceId,
								})
									.then(() => {
										navigate(`../${demoPages.login.path}`);
										dispatch({ type: 'FIRM_LOGOUT' });
									})
									.catch(() => {
										navigate(`../${demoPages.login.path}`);
										dispatch({ type: 'FIRM_LOGOUT' });
									});
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Logout')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>

			<OffCanvas setOpen={() => setNotificationModal(null)} isOpen={notificationModal !== null} isBodyScroll placement='end'>
				<OffCanvasHeader setOpen={() => setNotificationModal(null)}>
					<OffCanvasTitle id='upcomingEdit'>系統公告</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						{announcements.announcementList?.map((item) => (
							<div className='col-12' key={item.announcementId}>
								<Alert color='light'>
									<AlertHeading style={{ display: 'flex', flexDirection: 'column' }}>
										<div>
											{item.type?.map((type, index) => (
												<Badge color={ANNOUNCEMENTS_TYPE[type]?.color || 'dark'} isLight={darkModeStatus} key={index} style={{ margin: '0 4px 10px 0' }}>
													{ANNOUNCEMENTS_TYPE[type]?.name || ''}
												</Badge>
											))}
										</div>
										<div>
											<b>{item.name}</b>
										</div>
									</AlertHeading>
									<div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.content || '' }}></div>
									{/* <p style={{ whiteSpace: 'pre-line' }}>{item.content}</p> */}
									<div className='w-100 d-flex flex-row-reverse text-muted'>{moment(item.createDate).format('YYYY年 MMM Do, ah:mm')} 發布</div>
								</Alert>
							</div>
						))}
					</div>
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
};

export default User;
